import React from "react";
import logo from "../img/casement.17PM";
import logo2 from "../img/casement_1.jpg";

const Casement = () => {
  return (
    <>
      <div className="container-fluid  bg_3">
        <div className="container p-3">
          <h1 className=" special_font_head text-center  heading_colour">
            {" "}
            Casement Window
          </h1>
          <div className="row  mt-5">
            <div className="col-lg 3">
              {" "}
              <img
                // animation refer Description
                className="img-fluid upvc_img rounded animate__animated animate__bounceInLeft"
                src={logo}
              />
            </div>
            <div className="col-lg 6">
              <h4 className="special_font_head text-center">description</h4>
              <p className="upvc_font">
                Casement doors are hinged doors that swing open or closed on one
                side, similar to casement windows. They typically consist of one
                or more panels attached to hinges on one side of the door
                frame.Operates on hinges attached to one side of the door frame,
                allowing for outward or inward swinging motion.Can consist of a
                single panel or multiple panels, depending on the door's width
                and design. Equipped with secure locking mechanisms to ensure
                safety and security when closed.Provides a tight seal when
                closed, minimizing drafts and improving thermal
                insulation.Available in various materials, styles, and finishes
                to suit different architectural designs and aesthetic
                preferences.
              </p>
            </div>
            <div className="col-lg 3">
              {" "}
              <img
                className="img-fluid upvc_img rounded animate_right"
                src={logo2}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Casement;
