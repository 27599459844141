import React from "react";
import dobule from "../img/dobule.jpeg";
import dobule_1 from "../img/dobule_1.jpg";
const DobuleDoor = () => {
  return (
    <>
      <div className="container-fluid  bg_3">
        <div className="container p-3">
          <h1 className=" special_font_head text-center  heading_colour">
            {" "}
            DobuleDoor uPVC
          </h1>
          <div className="row  mt-5">
            <div className="col-lg 3">
              {" "}
              <img
                // animation refer Description
                className="img-fluid upvc_img rounded animate__animated animate__bounceInLeft"
                src={dobule}
              />
            </div>
            <div className="col-lg 6">
              <h4 className="special_font_head text-center">description</h4>
              <p className="upvc_font">
                Double door uPVC refers to a type of door system made from
                unplasticized polyvinyl chloride (uPVC), a durable and
                lightweight material known for its excellent insulation
                properties and low maintenance requirements. As the name
                suggests, double door uPVC systems consist of two door panels
                that are typically hinged on opposite sides of the door frame,
                providing a wide opening for entry or exit.Made from uPVC
                material, which is resistant to rotting, warping, and corrosion,
                making it ideal for exterior door applications.Consists of two
                door panels that open independently, offering versatility in
                operation and allowing for increased airflow and accessibility.
              </p>
            </div>
            <div className="col-lg 3">
              {" "}
              <img
                className="img-fluid upvc_img rounded animate_right"
                src={dobule_1}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DobuleDoor;
