import React from "react";
import logo from "../img/foldingdoor-img.jpeg";
import logo1 from "../img/fooldingdoor-img1.webp";
const Foldingdoor = () => {
  return (
    <div className="container-fluid  bg_3">
      <div className="container p-3">
        <h1 className=" special_font_head text-center  heading_colour">
          {" "}
          Folding doors
        </h1>
        <div className="row  mt-5">
          <div className="col-lg 3">
            {" "}
            <img
              // animation refer Description
              className="img-fluid upvc_img rounded animate__animated animate__bounceInLeft"
              src={logo}
            />
          </div>
          <div className="col-lg 6">
            <h4 className="special_font_head text-center">description</h4>
            <p className="upvc_font">
              Folding doors consist of multiple panels hinged together. When
              closed, they form a solid barrier, but when opened, they can be
              neatly folded to the side, creating a wide opening and maximizing
              space. This design allows for seamless integration between indoor
              and outdoor areas, bringing in natural light and creating an
              expansive feel. These doors are often made of materials like wood,
              aluminum, or uPVC, offering durability and weather resistance.
              They can feature various glazing options, such as glass panels, to
              enhance natural light and provide insulation. Folding doors are
              popular for their versatility, as they can be customized to fit
              different architectural styles and preferences.
            </p>
          </div>
          <div className="col-lg 3">
            {" "}
            <img
              className="img-fluid upvc_img rounded animate_right"
              src={logo1}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Foldingdoor;
