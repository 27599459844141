import React from "react";
import logo from "../img/tit and turn.jpeg";
import logo2 from "../img/img_1_1.jpg";
const Titand = () => {
  return (
    <>
      <div className="container-fluid  bg_3">
        <div className="container p-3">
          <h1 className=" special_font_head text-center  heading_colour">
            {" "}
            Tilt Turn Window
          </h1>
          <div className="row  mt-5">
            <div className="col-lg 3">
              {" "}
              <img
                // animation refer Description
                className="img-fluid upvc_img rounded animate__animated animate__bounceInLeft"
                src={logo}
              />
            </div>
            <div className="col-lg 6">
              <h4 className="special_font_head text-center">description</h4>
              <p className="upvc_font">
                Tilt and turn windows are versatile window systems that can be
                opened in two ways: tilting inwards from the top for ventilation
                or swinging open from the side for full access. They are
                typically operated using a single handle, which controls both
                the tilting and turning functions of the window.Offers two
                opening functions - tilt and swing - for flexibility in
                ventilation and access.The window sash tilts inwards from the
                top, allowing for secure ventilation while maintaining privacy
                and security.The window sash swings open from the side,
                providing full access for cleaning, egress, or emergency
                escape.Equipped with multi-point locking mechanisms for enhanced
                security and peace of mind.
              </p>
            </div>
            <div className="col-lg 3">
              {" "}
              <img
                className="img-fluid upvc_img rounded animate_right"
                src={logo2}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Titand;
