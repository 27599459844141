import React from "react";
import logo from "../img/slide.jpeg";
import logo1 from "../img/sliding_1.jpeg";
const Sliding = () => {
  return (
    <>
      <div className="container-fluid  bg_3">
        <div className="container p-3">
          <h1 className=" special_font_head text-center  heading_colour">
            {" "}
            Sliding Window
          </h1>
          <div className="row  mt-5">
            <div className="col-lg 3">
              {" "}
              <img
                // animation refer Description
                className="img-fluid upvc_img rounded animate__animated animate__bounceInLeft"
                src={logo}
              />
            </div>
            <div className="col-lg 6">
              <h4 className="special_font_head text-center">description</h4>
              <p className="upvc_font">
                Sliding windows are window systems that operate by sliding
                horizontally along a track, typically consisting of two or more
                panels. These windows provide a wide opening without protruding
                inward or outward, making them ideal for areas with limited
                space or where outward clearance is restricted.Operates by
                sliding one or more sashes horizontally along a track, allowing
                for smooth and effortless opening and closing.Most common
                sliding windows consist of two panels - one fixed and one
                movable - although configurations with multiple movable panels
                are also available.Features a sleek and minimalist design with
                narrow frames, maximizing the glass area for unobstructed views
                and natural light.
              </p>
            </div>
            <div className="col-lg 3">
              {" "}
              <img
                className="img-fluid upvc_img rounded animate_right"
                src={logo1}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sliding;
