import React from "react";
import logo4 from "../img/img_1_1.jpg";
import logo5 from "../img/img-2.jpeg";
import logo6 from "../img/img-5.13PM";
import logo7 from "../img/dobuledoor.jpeg";
import logo8 from "../img/half.jpeg";
import logo9 from "../img/trible.jpeg";
import { Link } from "react-router-dom";
import "../css/Collection.css";
const Collection = () => {
  return (
    <>
      <div className="container=fluid mt-3 bg_colour p-3">
        <h1 className="head_font text-center">Our top Collection</h1>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mt-3">
              <div className="card">
                <img
                  src={logo4}
                  className="card-img-top"
                  alt="Logo 4"
                  style={{ height: "220px" }}
                />
                <div className="card-body">
                  <h5 className="card-title">Tilt and Turn Window</h5>
                  <p className="card-text">
                    The Tilt and turn window is the window that can be opened
                    both outwards and inwards with the support of upvc tilt .
                  </p>
                  <Link to="/description">
                    <h6>Learn more</h6>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4  mt-3">
              <div className="card">
                <img
                  src={logo5}
                  className="card-img-top"
                  alt="Logo 5"
                  style={{ height: "220px" }}
                />
                <div className="card-body">
                  <h5 className="card-title">Fixed frame window</h5>
                  <p className="card-text">
                    A fixed frame window, also known as a picture window, is a
                    type of window that does not open or close.
                  </p>
                  <Link to="/description_1">
                    <h6>Learn more</h6>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4  mt-3">
              <div className="card">
                <img src={logo6} className="card-img-top" alt="Logo 6" />
                <div className="card-body">
                  <h5 className="card-title">uPVC Partition</h5>
                  <p className="card-text">
                    uPVC (unplasticized polyvinyl chloride) partitions offer a
                    versatile and durable solution for dividing interior spaces
                    in both residential and commercial settings.
                  </p>
                  <Link to="/description_2">
                    <h6>Learn more</h6>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* -------------------------2nd row---------------- */}
          <div className="row mt-3">
            <div className="col-lg-4 mt-3">
              <div className="card">
                <img
                  src={logo7}
                  className="card-img-top"
                  alt="Logo 4"
                  style={{ height: "220px" }}
                />
                <div className="card-body">
                  <h5 className="card-title">Double door</h5>
                  <p className="card-text">
                    Double doors consist of two separate door panels hinged on
                    opposite sides of an entryway, providing a wider opening for
                    passage.
                  </p>
                  <Link to="/description_3">
                    <h6>Learn more</h6>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4  mt-3">
              <div className="card">
                <img
                  src={logo8}
                  className="card-img-top"
                  alt="Logo 5"
                  style={{ height: "220px" }}
                />
                <div className="card-body">
                  <h5 className="card-title">Half partiton </h5>
                  <p className="card-text">
                    "Half partition" generally refers to a wall or divider that
                    extends only partway up from the floor, rather than reaching
                    all the way to the ceiling
                  </p>
                  <Link to="/halfparition">
                    <h6>Learn more</h6>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4  mt-3">
              <div className="card">
                <img
                  src={logo9}
                  className="card-img-top"
                  alt="Logo 6"
                  style={{ height: "220px" }}
                />
                <div className="card-body">
                  <h5 className="card-title">Casement trible open window </h5>
                  <p className="card-text">
                    A casement triple open window offers three panels that swing
                    outward, allowing for customizable ventilation and airflow
                    control.
                  </p>
                  <Link to="/casement">
                    <h6>Learn more</h6>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Collection;
