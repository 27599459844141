import React from "react";
import "../css/Footer.css";
const Footer = () => {
  return (
    <>
      <div className=" container-fluid mt-3 bg_footer p-3">
        <div className="row p-3 text-center">
          <div className="col-lg-6 ">
            <h5 className="footer_font text-white text-center">
              Stay Connected
            </h5>
            <div>
              <div className="d-flex gap-2 mt-3 justify-content-center">
                <i class="fa-brands fa-facebook icons "></i>
                <p className="font text-white footer_text">
                  {" "}
                  <a
                    className=" connection_link"
                    href="https://www.facebook.com/profile.php?id=100024645859078&mibextid=ZbWKwL"
                  >
                    facebook
                  </a>
                </p>
              </div>
              <div className="d-flex gap-2 justify-content-center">
                <i class="fa-brands fa-instagram  icons  insta"></i>
                <p className="font text-white footer_text">
                  <a
                    className=" connection_link"
                    href="https://www.instagram.com/ever_green_windows/"
                  >
                    {" "}
                    Instagram{" "}
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 text-center ">
            {" "}
            <h5 className="text-primary footer_font">
              {" "}
              <i class="fa-solid fa-phone "></i>{" "}
              <span className="footer_head" style={{ marginLeft: "7px" }}>
                Call
              </span>
            </h5>
            <p className="text1 text-white mt-4 footer_text ">
              {" "}
              +91-9498320189 (or) <br />
              +91-9489728849 (or) <br />
              +91-9489729590{" "}
            </p>
          </div>

          {/* <div className="col-lg-4 text-center">
            <h5 className="text-primary footer_font">
              {" "}
              <i class="fa-solid fa-location-dot"></i>
              <span
                className="footer_head"
                style={{ marginLeft: "7px" }}
              ></span>
              <span className="footer_head">Email</span>
            </h5>
            <p className=" text-white mt-4 footer_text">
              evergreenwindowsdoors@gmail.com
            </p>
          </div> */}
          {/* <div className="col-lg-3 text-center">
            <h5 className="text-primary footer_font">
              {" "}
              <i class="fa-solid fa-location-dot"></i>
              <span
                className="footer_head"
                style={{ marginLeft: "7px" }}
              ></span>
              <span className="footer_head">Address</span>
            </h5>
            <p className="text1  text-white mt-4 footer_text">
              2/807,Near by Ms Vidhyalaya, Byepass Service Road,
              <br /> Velayuthampalayam Post,
              <br />
              Avinashi,Tirupur-641 654.
            </p>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Footer;
