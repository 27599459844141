import React from "react";
import logo from "../img/sliding_1.jpeg";
import logo1 from "../img/mqsnet.jpeg";
import logo2 from "../img/sliding-3.jpeg";
import logo3 from "../img/fixing.jpg";
import logo4 from "../img/img-1.jpeg";
import logo5 from "../img/img-2.jpeg";
import logo6 from "../img/img-3.jpeg";
import logo7 from "../image/img-8.avif";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";
import "../css/Home.css";
import Collection from "../compond/collection";
import Footer from "../compond/Footer";
const Home = () => {
  return (
    <>
      <div className="conatainer-fluid   ">
        <div className=" media_head img">
          <h2 className="   text-white text-center  ">
            {" "}
            Ever Green Windows <br /> And Doors.{" "}
          </h2>
        </div>
        <div className="container mt-4">
          <div className="row">
            <h2 className="text-center heading_colour">
              Open Your Windows to a World of Assistance <br />- Let Us Help You
              Shine
            </h2>
            <p className="text-center para_font mt-3">
              Whether you're looking for stylish designs, energy-efficient
              options, or reliable installation services, we've got you covered.
              Our commitment to quality and customer satisfaction ensures that
              every project is executed with precision and care.Explore our
              range of products and services to discover how we can help elevate
              your home or business with beautiful, functional windows that
              exceed your expectations. Trust us to bring your vision to life
              and make your windows the envy of the neighborhood."
            </p>
          </div>
        </div>
        <div className="container-fluid bg_colour p-3">
          <div className="container media_head ">
            <div className="row mt-4 ">
              <h6 className="text-center home_head  ">
                EVERGREEN ULTIAMTE SLIDING DOORS
              </h6>
              <h1 className="head_font text-center ">
                Exquisite Premium Collection
              </h1>
              {/* --------------------------------- 1 CARD---------------------------------- */}
              <div className="col-lg-4 mt-4">
                <div class="card" id="casementWindowCard">
                  <div class="card-inner">
                    <div class="card-front">
                      <img src={logo} class="card-img-top" alt="..." />
                      <div class="card-body">
                        <h5 class="card-title  ">Sliding Window</h5>
                        <p class="card-text normal_para">
                          Sliding windows feature two or more sashes that slide
                          horizontally along tracks. They operate smoothly.
                        </p>
                      </div>
                    </div>
                    <div class="card-back p-3 back_font">
                      <p>
                        Sliding windows offer smooth horizontal movement, making
                        them ideal for compact spaces. Their sleek design
                        enhances both aesthetics and functionality. With options
                        in materials like vinyl, wood, or aluminum, they cater
                        to diverse architectural styles. These windows provide
                        efficient ventilation and require minimal maintenance.
                        Energy-efficient features help regulate indoor
                        temperatures, reducing utility costs.Sliding window
                        technique is commonly used to solve problems like
                        finding the maximum sum subarray of a fixed size.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* --------------------------------------2ND CARD---------------------- */}
              <div className="col-lg-4 mt-4">
                <div class="card" id="casementWindowCard">
                  <div class="card-inner">
                    <div class="card-front">
                      <img src={logo1} class="card-img-top" alt="..." />
                      <div class="card-body">
                        <h5 class="card-title">
                          Sliding window with mosquito net
                        </h5>
                        <p class="card-text normal_para">
                          A sliding window mosquito screen is a type of screen
                          designed to fit over windows.
                        </p>
                      </div>
                    </div>
                    <div class="card-back p-3 back_font">
                      <p>
                        A sliding window mosquito net is a mesh screen that is
                        installed over a window opening to prevent mosquitoes
                        and other insects from entering a room while still
                        allowing fresh air to flow through. The net is usually
                        made of a fine mesh material, such as nylon or polyester
                        that can block even the smallest insects, and is
                        designed to fit snugly over the window frame.The netting
                        is typically attached to a sliding frame that allows it
                        to be opened or closed as needed, making it easy to
                        access the window for cleaning or other purposes. When
                        the net is closed, it creates a barrier that prevents
                        mosquitoes from entering the room.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* -----------------3RD CARD--------------- */}
              <div className="col-lg-4 mt-4">
                <div class="card" id="casementWindowCard">
                  <div class="card-inner">
                    <div class="card-front">
                      <img src={logo2} class="card-img-top" alt="..." />
                      <div class="card-body">
                        <h5 class="card-title">Casement Window</h5>
                        <p class="card-text normal_para">
                          A casement window is the one that is fixed to the
                          frames or profiles with the help of one or more hinges
                          at the sides.
                        </p>
                      </div>
                    </div>
                    <div class="card-back p-3 back_font">
                      <p className="para ">
                        They are used either in pairs or single within a frame.
                        These windows are hinged on the outside. Often a
                        casement stay is used to hold open the casement windows.
                        These windows are the best options for the purpose of
                        natural ventilation. The casement windows can be hinged
                        to open outward while angled accordingly in order to
                        direct breezes inside the buildings.The casement windows
                        are also soundproof and dustproof. They guarantee 100%
                        opening. Cleaning is convenient as the entire sash
                        swings open for access to both interior and exterior
                        surfaces.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ---------------whatsapp-icons--------- */}
      <div className="whatsapp-icon">
        <a className="footer-link" href="https://wa.me/919489729590">
          <i className="fa-brands fa-whatsapp icons"></i>
        </a>
      </div>
      {/* ----------------content------- */}
      <div className="container mt-5">
        <div className="row ">
          <div className="col-lg-5 back_font ">
            <h3 className="head_font text-center mt-5">
              Ever Green <br /> Window& DOORS
            </h3>
            <p className="mt-3  para_1">
              Whether you're renovating your home's appearance, updating
              worn-out windows and doors, or seeking to improve energy
              efficiency,evergreenwindowsdoors is your partner every step of the
              way. Our team is committed to providing comprehensive assistance
              for your replacement window and door project, ensuring a seamless
              and satisfying experience.
            </p>
            {/* <button className="btn btn-warning mt-2   ">
              <h6> View more</h6>
            </button> */}
          </div>
          <div className="col-lg-7">
            <img
              className="img-fluid"
              src={logo3}
              style={{ height: "450px" }}
            />
          </div>
        </div>
      </div>
      {/* -----------------------box------------ */}
      <div className="container bg_3 mt-5 ">
        <div className="row mt-4 ">
          <div className="col-lg-5 back_font p-4">
            {" "}
            <h1 className="percent text-center mt-5">100%</h1>
          </div>
          <div className="col-lg-6  p-4 back_font">
            <h2 className="head ">
              Our objective is to achieve a 100% hassle-free experience.
            </h2>
            <p className="para_1">
              Our objective is to ensure a seamless experience from start to
              finish. At MI Windows and Doors, we're committed to delivering
              hassle-free solutions for all your home projects, providing
              support every step of the way until they are successfully
              accomplished.
            </p>
          </div>
          <div className="col-lg-1 p-4"></div>
        </div>
      </div>
      {/* ----------------------content-1------------ */}
      <Collection />
      {/* -----------footer------- */}
      <Footer />
    </>
  );
};

export default Home;
