import React from "react";
import upvc from "../img/upvs img.jpg";
import upve_1 from "../img/upvcimg_1.38PM";
import "../css/Upvcwindow.css";
const Upvcwindow = () => {
  return (
    <>
      <div className="container-fluid  bg_3">
        <div className="container p-3">
          <h1 className=" special_font_head text-center  heading_colour">
            {" "}
            SLIDING WINDOW WITH MOSQUITO NET
          </h1>
          <div className="row  mt-5">
            <div className="col-lg 3">
              {" "}
              <img
                // animation refer Description
                className="img-fluid upvc_img rounded animate__animated animate__bounceInLeft"
                src={upvc}
              />
            </div>
            <div className="col-lg 6">
              <h4 className="special_font_head text-center">description</h4>
              <p className="upvc_font">
                Efficient Thermal Insulation. Window Magic windows and doors are
                ideal for every climatic condition and result in providing a
                cozy and comfortable atmosphere in your house. uPVC Efficient
                Thermal Insulation. Window Magic windows and doors are ideal for
                every climatic condition and result in providing a cozy and
                comfortable atmosphere in your house. uPVC windows and doors
                have efficient thermal insulation resulting in minimum heat lose
                in Winters and reduce heat gain in summers . windows and doors
                have efficient thermal insulation resulting in minimum heat lose
                in Winters and reduce heat gain in summers .
              </p>
            </div>
            <div className="col-lg 3">
              {" "}
              <img
                className="img-fluid upvc_img rounded animate_right"
                src={upve_1}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Upvcwindow;
