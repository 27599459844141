import React from "react";
import logo from "../img/sliding-door.jpg";
import logo1 from "../img/slidingdoor_1.jpg";
const Slidingdoor = () => {
  return (
    <div className="container-fluid  bg_3">
      <div className="container p-3">
        <h1 className=" special_font_head text-center  heading_colour">
          {" "}
          Sliding Doors
        </h1>
        <div className="row  mt-5">
          <div className="col-lg 3">
            {" "}
            <img
              // animation refer Description
              className="img-fluid upvc_img rounded animate__animated animate__bounceInLeft"
              src={logo}
            />
          </div>
          <div className="col-lg 6">
            <h4 className="special_font_head text-center">description</h4>
            <p className="upvc_font">
              Low-cost alternative. A sliding door is a great way to add more
              space without having to invest in a whole new window. Modern.
              Sliding doors are a modern addition to any home and make the
              perfect addition to an open-plan living area. Easy to open and
              close. Sliding doors can also be used as an extra entrance or exit
              point, making them very convenient if you have pets or children.
              Simple to clean. The glass panels are easy to wipe down and clean,
              so you don't need to worry about them getting dirty quickly!,
              Sliding doors are a popular architectural feature in both
              residential and commercial settings. They consist of panels that
              glide horizontally along tracks, allowing for smooth and
              effortless opening and closing.
            </p>
          </div>
          <div className="col-lg 3">
            {" "}
            <img
              className="img-fluid upvc_img rounded animate_right"
              src={logo1}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slidingdoor;
