import React from "react";
import logo from "../img/casement-door.jpg";
import logo1 from "../img/casement-img.jpeg";
const Casementdoor = () => {
  return (
    <div className="container-fluid  bg_3">
      <div className="container p-3">
        <h1 className=" special_font_head text-center  heading_colour">
          {" "}
          Casement Window
        </h1>
        <div className="row  mt-5">
          <div className="col-lg 3">
            {" "}
            <img
              // animation refer Description
              className="img-fluid upvc_img rounded animate__animated animate__bounceInLeft"
              src={logo}
            />
          </div>
          <div className="col-lg 6">
            <h4 className="special_font_head text-center">description</h4>
            <p className="upvc_font">
              Casement doors are hinged at the side and swing outward or inward,
              similar to casement windows. They offer a wide opening for easy
              access and ventilation. Typically, they are made of materials like
              wood, aluminum, or uPVC (unplasticized polyvinyl chloride),
              offering durability and weather resistance. These doors often
              feature multi-point locking systems for security and can be
              customized with various finishes, colors, and hardware options to
              match the style of your home. They are popular for their aesthetic
              appeal and functionality, providing a seamless transition between
              indoor and outdoor spaces.
            </p>
          </div>
          <div className="col-lg 3">
            {" "}
            <img
              className="img-fluid upvc_img rounded animate_right"
              src={logo1}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Casementdoor;
