import React from "react";
import logo from "../img/up_img.jpg";
import logo1 from "../img/up_img1.jpg";
import logo2 from "../img/up_img3.jpg";
import logo3 from "../img/up_img4.jpg";
import logo4 from "../img/up_img5.jpg";
import logo5 from "../img/up_img6.avif";
import logo6 from "../img/up_img7.jpg";
import logo7 from "../img/up_img8.jpg";
import logo8 from "../img/building_img.jpeg";
import logo9 from "../img/up_img10.jpeg";
import logo10 from "../img/up_img12.jpeg";
import "../css/Update.css";
import { Link } from "react-router-dom";

const Update = () => {
  return (
    <>
      <div className="container-fluid  p-4 bg_colour">
        <div className="container">
          <h3 className="text-center  heading_colour special_font_head">
            Our Updates
          </h3>
          <div className="row  mt-4 justify-content-between">
            <div className="col-lg-4">
              {" "}
              <div class="zoom-effect">
                <img
                  className="img-fluid up_img rounded"
                  src={logo}
                  alt="Logo"
                />
              </div>
            </div>
            <div className="col-lg-4">
              {" "}
              <div className="zoom-effect">
                <img className="img-fluid  up_img rounded" src={logo2} />
              </div>
            </div>
            <div className="col-lg-4">
              {" "}
              <div className="zoom-effect">
                <img className="img-fluid  up_img rounded" src={logo3} />
              </div>
            </div>
          </div>
          {/* -----------------2nd------- */}
          <div className="row mt-3">
            <div className="col-lg-12">
              <img className=" up_img1 rounded " src={logo1} />
            </div>
          </div>
          {/* -----------------3rd------------- */}
          <div className="row  justify-content-between mt-4">
            <div className="col-lg-4">
              {" "}
              <div className="zoom-effect">
                <img className="img-fluid  up_img rounded" src={logo4} />
              </div>
            </div>
            <div className="col-lg-4">
              {" "}
              <div className="zoom-effect">
                <img className="img-fluid  up_img rounded" src={logo5} />
              </div>
            </div>
            <div className="col-lg-4">
              {" "}
              <div className="zoom-effect">
                <img className="img-fluid  up_img rounded" src={logo6} />
              </div>
            </div>
          </div>
          {/* ---------------------4th--------------- */}
          <div className="row mt-3">
            <div className="col-lg-12 zoom-effect">
              <img className=" up_img1 rounded " src={logo8} />
            </div>
          </div>
          {/* <Courisal /> */}
          {/* -----------------4th---------------- */}
          {/* ---------whatsapp------- */}
          <div className="whatsapp-icon">
            <a className="footer-link" href="https://wa.me/919489729590">
              <i className="fa-brands fa-whatsapp icons"></i>
            </a>
          </div>
          {/* ---------- */}
          <div className="row  justify-content-between mt-4">
            <div className="col-lg-4">
              {" "}
              <div className="zoom-effect">
                <img className="img-fluid  up_img rounded" src={logo7} />
              </div>
            </div>
            <div className="col-lg-4">
              {" "}
              <div className="zoom-effect">
                <img className="img-fluid  up_img rounded" src={logo9} />
              </div>
            </div>
            <div className="col-lg-4">
              {" "}
              <div className="zoom-effect">
                <img className="img-fluid  up_img rounded" src={logo10} />
              </div>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-lg-12">
              <button className="btn bg-primary media mt-5">
                <Link to="/update_2" className="text-white link_btn">
                  Next Page
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Update;
