import React from "react";
import logo5 from "../img/img-2.jpeg";
import Footer from "../compond/Footer";
const Description_1 = () => {
  return (
    <>
      {" "}
      <div className="container-fluid bg_3">
        <div className="container ">
          <div className="row   ">
            <h3 className=" text-center mt-4">Fixed frame window</h3>
            <div className="col-lg-7">
              <img
                className="img-fluid img_des  mt-5"
                src={logo5}
                alt="Description"
              />
            </div>
            <div className="col-lg-5 animate__animated animate__bounceInLeft mt-1">
              <h3 className=" text-center mt-5">DESCRIPTION</h3>
              <p className="mt-3  para_1 specialfont_para">
                A fixed frame window, also known as a picture window, is a
                stationary window that cannot be opened or closed. It consists
                of a single pane of glass that is securely fixed within a frame.
                One of the primary purposes of fixed frame windows is to provide
                unobstructed views of the surrounding environment. They are
                often installed in areas where ventilation is not a priority,
                such as in living rooms, dining areas, or hallways. Fixed frame
                windows are valued for their aesthetic appeal and ability to
                maximize natural light intake while framing scenic views. They
                contribute to the overall architectural design of a building,
                adding a sense of openness and spaciousness to interior spaces.
                Additionally, their seamless appearance enhances the visual
                appeal of both modern and traditional architectural styles.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Description_1;
