import React from "react";
import logo4 from "../img/img_1_1.jpg";
import "../css/Description.css";
import Footer from "../compond/Footer";
const Description = () => {
  return (
    <>
      <div className="container-fluid bg_3">
        <div className="container ">
          <div className="row   ">
            <h3 className=" text-center mt-4">Tilt and Turn Window</h3>
            <div className="col-lg-5 animate__animated animate__bounceInLeft mt-4">
              <h3 className=" text-center mt-5">DESCRIPTION</h3>
              <p className="mt-3  para_1 specialfont_para">
                The Tilt and turn window is the window that can be opened both
                outwards and inwards with the support of upvc tilt and turn
                hinges on the frames or profiles. These windows come with two
                distinct features. One can either swing it in like a door or can
                tilt the top of the sash installed inside the room for
                ventilation purposes. There is an only single handle for both
                the operation. These dustproof and soundproof Tilt and Turn
                windows are used in the spaces essential simultaneously for
                ventilation and beautiful scenic landscapes like that in Living
                areas, kitchens and balconies. The 3 attractive colours with
                unique strength and durability make these Tilt and Turn windows
                from Weatherseal quite famous in the fenestration industry.
              </p>
            </div>
            <div className="col-lg-7">
              <img
                className="img-fluid img_des  mt-5"
                src={logo4}
                alt="Description"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Description;
