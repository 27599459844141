import React from "react";
import logo7 from "../img/dobuledoor.jpeg";
const Description_3 = () => {
  return (
    <>
      <>
        {" "}
        <div className="container-fluid bg_3">
          <div className="container ">
            <div className="row   ">
              <h3 className=" text-center mt-4">uPVC Partition</h3>

              <div className="col-lg-5 animate__animated animate__bounceInLeft mt-1">
                <h5 className=" text-center mt-5 heads_font">DESCRIPTION</h5>
                <p className="mt-3  para_1 ">
                  Having double doors installed,and especially when those doors
                  are external-to- internal ,grants better access and
                  facilitates the moving in and out of larger household
                  items.Also,double doors open larger spaces in walls,especially
                  useful when airing out a house.etc. Like most things in house
                  construction,there are trade-offs.Double doors also carry
                  their share of problems,as well as solutions.Double doors,
                  also known as French doors, are a type of door consisting of
                  two separate panels that are hinged on opposite sides of a
                  door frame and typically swing open from the center. They are
                  often used for exterior access to patios, gardens, or
                  balconies, as well as for interior entrances between rooms.
                </p>
              </div>
              <div className="col-lg-7">
                <img
                  className="img-fluid img_des  mt-5"
                  src={logo7}
                  alt="Description"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Description_3;
