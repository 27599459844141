import React from "react";
import logo9 from "../img/trible.jpeg";
const Description_5 = () => {
  return (
    <>
      {" "}
      <div className="container-fluid bg_3">
        <div className="container ">
          <div className="row   ">
            <h3 className=" text-center mt-4">Casement trible open window</h3>

            <div className="col-lg-5 animate__animated animate__bounceInLeft mt-1">
              <h5 className=" text-center mt-5 heads_font">DESCRIPTION</h5>
              <p className="mt-3  para_1 ">
                A casement triple open window is a type of window design that
                consists of three individual casement windows hinged on the
                sides, allowing them to swing open outward like doors.The window
                is divided into three sections, each of which can be opened
                independently. This design provides flexibility in controlling
                ventilation and airflow, as each section can be adjusted
                according to preference.Each casement window is hinged on one
                side, allowing it to swing open outward. This design maximizes
                the opening area, providing excellent ventilation and
                unobstructed views when the windows are fully open.Casement
                triple open windows are suitable for various architectural
                styles and can be installed in different areas of a building,
                including bedrooms, living rooms, kitchens, and offices.
              </p>
            </div>
            <div className="col-lg-7">
              <img
                className="img-fluid img_des  mt-5"
                src={logo9}
                alt="Description"
                style={{ height: "500px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Description_5;
