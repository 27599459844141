import React from "react";
import logo from "../img/UPVC-Tilt-Turn-Door.jpg";
import logo1 from "../img/tit and turn.jpeg";
const Tiltandtilldoor = () => {
  return (
    <div className="container-fluid  bg_3">
      <div className="container p-3">
        <h1 className=" special_font_head text-center  heading_colour">
          {" "}
          Tilt and Turn Door
        </h1>
        <div className="row  mt-5">
          <div className="col-lg 3">
            {" "}
            <img
              className="img-fluid upvc_img rounded animate__animated animate__bounceInLeft"
              src={logo}
            />
          </div>
          <div className="col-lg 6">
            <h4 className="special_font_head text-center">description</h4>
            <p className="upvc_font">
              A tilt and turn door is a type of window or door that can swing
              open in two different ways: tilting inwards from the top or
              swinging open from the side like a traditional door. This dual
              functionality offers versatility and practicality, allowing for
              controlled ventilation in the tilted position while also providing
              a wide opening for easy access or cleaning when swung open. Tilt
              and turn doors are commonly used in modern architectural designs
              and are favored for their space-saving qualities and ability to
              enhance energy efficiency and security.
            </p>
          </div>
          <div className="col-lg 3">
            {" "}
            <img
              className="img-fluid upvc_img rounded animate_right"
              src={logo1}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tiltandtilldoor;
