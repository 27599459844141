import React from "react";

const Product = () => {
  return (
    <div class="container">
      <div class="animate__animated animate__bounce animate__repeat-2">
        Example
      </div>
    </div>
  );
};

export default Product;
