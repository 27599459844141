import React from "react";
import img from "../img/partition.13PM";
import img_1 from "../img/partition_1.jpeg";
const Partition = () => {
  return (
    <>
      <div className="container-fluid  bg_3">
        <div className="container p-3">
          <h1 className=" special_font_head text-center  heading_colour">
            {" "}
            Upvc partition
          </h1>
          <div className="row  mt-5">
            <div className="col-lg 3">
              {" "}
              <img
                // animation refer Description
                className="img-fluid upvc_img rounded animate__animated animate__bounceInLeft"
                src={img}
              />
            </div>
            <div className="col-lg 6">
              <h4 className="special_font_head text-center">description</h4>
              <p className="upvc_font">
                material, a durable and lightweight plastic known for its
                versatility and performance. These partitions are used to
                separate spaces within a building, such as offices, homes, or
                commercial areas, providing privacy, sound insulation, and
                aesthetic appeal. Made from uPVC material, which is resistant to
                moisture, corrosion, and rotting, making it suitable for
                interior applications.Available in various styles, sizes, and
                configurations to suit different space requirements and design
                preferences.Lightweight and easy to install, allowing for quick
                and hassle-free partitioning of interior spaces without the need
                for heavy construction work.
              </p>
            </div>
            <div className="col-lg 3">
              {" "}
              <img
                className="img-fluid upvc_img rounded animate_right"
                src={img_1}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Partition;
