import React from "react";
import logo from "../img/FRp doors.png";
import logo2 from "../img/FRP_1.png";
const Upvc = () => {
  return (
    <>
      <div className="container-fluid  bg_3">
        <div className="container p-3">
          <h1 className=" special_font_head text-center  heading_colour">
            {" "}
            FRP DOORS
          </h1>
          <div className="row  mt-5">
            <div className="col-lg 3">
              {" "}
              <img
                // animation refer Description
                className="img-fluid upvc_img rounded animate__animated animate__bounceInLeft"
                src={logo}
              />
            </div>
            <div className="col-lg 6">
              <h4 className="special_font_head text-center">description</h4>
              <p className="upvc_font">
                These FRP moulded glass doors are avaliable in more than 100
                glass designs and 50 diffrent types of door models in natiral
                wood grain finish, glossy finish,matt finish,leather finish
                shades. These FRP moulded doors are having thickness up to 3mm
                FRP skin.Outer Frame are moulded over the well seasoned hardwood
                logs in all the sides covered with high quality fibre reinforced
                material formimg a sandwick panel.Our dors are made of
                compositematerial which combine the durabilty & versatality of
                polyester matrix with the strength of glass fiber.
              </p>
            </div>
            <div className="col-lg 3">
              {" "}
              <img
                className="img-fluid upvc_img rounded animate_right"
                src={logo2}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Upvc;
