import React from "react";
import logo from "../img/logot.png";
import "../css/Contact.css";
import { Link } from "react-router-dom";
const Contact = () => {
  return (
    <>
      <div className="container-fluid p-5 contact_bg">
        <div
          className="container p-5  bg-black rounded"
          style={{ backgroundColor: "rgb(255,235,232)" }}
        >
          <div className="row">
            <div className="col-lg-12">
              <h2 className="font text-center text-white">
                Select your Window and Doors with Us
              </h2>
              <div className="d-flex flex-column flex-lg-row gap-5 mt-4 justify-content-center">
                <div className="d-flex gap-2 color">
                  <i class="fa-solid fa-lightbulb mt-2"></i>

                  <p className="font" style={{ fontSize: "19px" }}>
                    Save Windows Ideas
                  </p>
                </div>
                <div className="d-flex gap-2 color ">
                  <i className="fa-solid fa-heart mt-2"></i>
                  <p className="font" style={{ fontSize: "19px" }}>
                    Shortlist your window
                  </p>
                </div>
                <div className="d-flex gap-2 ml-2 color">
                  <i class="fa-solid fa-square-check mt-2"></i>
                  <p className="font" style={{ fontSize: "19px" }}>
                    Save your money
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid ">
          <div className="container mt-5 ">
            <div className="row justify-content-around">
              <div className="col-lg-4 text-center ">
                {" "}
                <h4 className="special_font_head mt-4">
                  {" "}
                  Evergreen Windoow Doors.
                </h4>{" "}
                <img className="img-fluid logo_img" src={logo} />{" "}
                <p>
                  Ever Green Windows & Doors presents a comprehensive range of
                  high- performance uPVC door and window solutions
                </p>
              </div>
              <div className="col-lg-4  text-center">
                <h4 className="mt-4 special_font_head ">Quick Links</h4>
                <Link className="link_style" to="/">
                  Home
                </Link>
                <br />

                <Link className="link_style" to="/about">
                  About
                </Link>
                <br />
                <Link className="link_style">Product</Link>
                <br />
                <Link className="link_style" to="/update">
                  Updates
                </Link>
                <br />
                <Link className="link_style" to="/contactUs">
                  Contact us
                </Link>
              </div>
              <div className="col-lg-4  text-center">
                <h4 className="mt-4 special_font_head  text-center">Address</h4>
                <h5 className="mt-4 special_font_head  text-center">
                  {" "}
                  head Office:
                </h5>
                <p>
                  2/807, Bypass service Road,Near by MS Vidyalaya,
                  Velayuthampalayam (Po), Avinashi,Tiruppur, Tamil Nadu, 641654
                </p>
                <p>
                  <span style={{ fontWeight: "650", fontSize: "18px" }}>
                    Email:
                  </span>{" "}
                  evergreenwindowsdoors@gmail.com.
                </p>
                <br />
                <span>
                  <h5
                    className="special_font_head "
                    style={{ display: "inline" }}
                  >
                    Call Us On:
                  </h5>
                  +91-9498320189
                </span>

                <h5 className="mt-4 special_font_head  text-center">
                  {" "}
                  branch Office:
                </h5>
                <p>
                  323, Pappa Nagar, Vilar Road, vilar(po), Thanjavur 613 006.
                </p>

                <p>
                  <span style={{ fontWeight: "650", fontSize: "18px" }}>
                    Email:
                  </span>{" "}
                  evergreenwindows49@gmail.com.
                </p>
                <br />
                <span>
                  <h5
                    className="special_font_head "
                    style={{ display: "inline" }}
                  >
                    Call Us On:
                  </h5>
                  +91-95853 15102,
                  <br /> +91- 94897 28849
                </span>
              </div>
            </div>
          </div>
          <div className="whatsapp-icon">
            <a className="footer-link" href="https://wa.me/919489729590">
              <i className="fa-brands fa-whatsapp icons"></i>
            </a>
          </div>

          <div className="container">
            <div className="row">
              <h5 className="special_font_head ">Head Office:</h5>
              <p>
                2/807,Near msVidhyalaya, Byepass Service Road, Velayuthampalayam
                Post, Avinashi.
              </p>
              <div className="col-lg-12 mt-2">
                <iframe
                  className="map img-fluid"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3914.206250490978!2d77.2698052!3d11.1723594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba903c65ce9e48b%3A0x64fdc818f3d35cb6!2sEvergreen%20windows%20%26%20Doors!5e0!3m2!1sen!2sin!4v1718512306225!5m2!1sen!2sin"
                  allowfullscreen=""
                  // width="600"
                  // height="450"
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>

          <div className="container mt-4">
            <div className="row">
              <span>
                <h5 className="special_font_head ">Branch office:</h5> 323,
                Pappa Nagar, Vilar Road, vilar(po), Thanjavur 613 006.
              </span>
              <div className="col-lg-12 mt-2">
                <iframe
                  className="map img-fluid"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.7661085292502!2d79.1504638!3d10.7525009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baab9e4a62cd0c1%3A0xe3ad8d6a7560dd99!2sEver%20green%20windows%20%26%20Doors!5e0!3m2!1sen!2sin!4v1718512776486!5m2!1sen!2sin"
                  // width="600"
                  // height="450"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <h5 className="footer_font">Stay Connected</h5>
            <div className="col-lg-12 d-flex">
              <div className=" mt-3 gap-5 d-flex">
                <div className="d-flex gap-2 ">
                  <i class="fa-brands fa-instagram  icons  insta"></i>
                  <h6 className=" footer_text">
                    {" "}
                    <a
                      className="footer-link"
                      href="https://www.instagram.com/ever_green_windows/"
                    >
                      Instagram
                    </a>
                  </h6>
                </div>
                <div className="d-flex  gap-2 ">
                  <i class="fa-brands fa-facebook icons "></i>
                  <h6 className="footer_text">
                    <a
                      className="footer-link"
                      href="https://www.facebook.com/profile.php?id=100024645859078&mibextid=ZbWKwL"
                    >
                      facebook
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
