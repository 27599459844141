import React from "react";
import logo1 from "../img/up_img6.avif";
import logo2 from "../img/up_img13.jpeg";
import logo3 from "../img/up_img14.jpeg";
import logo4 from "../img/up_img15.jpeg";
import logo5 from "../img/main.jpeg";
import logo6 from "../img/main_1.jpeg";
import logo7 from "../img/up_img16.jpeg";
import logo8 from "../img/up_img17.jpeg";

import { Link, useNavigate } from "react-router-dom";
const Update_2 = () => {
  const navigate = useNavigate();

  const previous = () => {
    navigate("/update");
  };
  return (
    <>
      {/* <div className="container-fluid colour">
        <div className="container p-4">
          <h1 className="special_font_head text-center  heading_colour">
            {" "}
            Ever Green Updates
          </h1>
          <div className="row mt-5 ">
            <div className="col-lg-5 ">
              <p className="up_para  ">
                uPVC windows an excellent choice for your home. <br />
                uPVC windows manufacture in Tirupur <br />
                uPVC windows manufacture in Avinashi <br />
                uPVC windows manufacture in avinashi road <br />
                uPVC windows manufacture in palladam <br />
                uPVC windows manufacture in tirupur road <br />
                uPVC windows manufactures in Tirupur <br />
                uPVC windows in Thanjavur <br />
                guts uPVC windows manufacture in Thanjavur <br />
                guts uPVC in Thanjavur <br />
                Guts uPVC windows Supplires in Thanjavur <br />
              </p>
            </div>
            <div className="col-lg-7">
              <img className="img-fluid up_img" src={logo1} />
            </div>
          </div>
          <div className="row mt-5 ">
            <div className="col-lg-7">
              <img
                className="img-fluid rounded "
                src={logo2}
                style={{ height: "500px", width: "600px" }}
              />
            </div>
            <div className="col-lg-5 ">
              <p className="up_para  ">
                uPVC windows manufacture in Tirupur <br />
                uPVC windows manufacture in Avinashi <br />
                uPVC windows manufacture in avinashi road <br />
                uPVC windows manufacture in palladam <br />
                uPVC windows manufacture in tirupur road <br />
                uPVC windows manufactures in Tirupur <br />
                uPVC windows suppliers in Tirupur <br />
                uPVC windows suppliers in avinashi <br />
                uPVC windows manufacture in Thanjavur <br />
                uPVC windows manufacture in Tiruchy <br />
                uPVC windows suppliers in Thanjavur <br />
                uPVC windows in Thanjavur <br />
                guts uPVC windows manufacture in Thanjavur <br />
                guts uPVC in Thanjavur <br />
                Guts uPVC windows Supplires in Thanjavur <br />
              </p>
            </div>
          </div>
          <div className="row mt-5 ">
            <div className="col-lg-5 ">
              <p className="up_para mt-4 ">
                Sliding Window with. Bug Mesh. Prevents entry of insects and
                mosquitoes into home, Offers good ventilation.
                <br />
                <br />
                uPVC windows an excellent choice for your home.
                <br />
                uPVC windows manufacture in Tirupur
                <br />
                uPVC windows manufacture in Avinashi
                <br />
                uPVC windows manufacture in avinashi road
                <br />
                uPVC windows manufacture in palladam
                <br />
                uPVC windows manufacture in tirupur road
                <br />
                uPVC windows manufactures in Tirupur
                <br />
                uPVC windows suppliers in Tirupur
                <br />
                uPVC windows suppliers in avinashi
                <br />
                uPVC windows manufacture in Thanjavur
                <br />
              </p>
            </div>
            <div className="col-lg-7">
              <img
                className="img-fluid rounded "
                src={logo3}
                style={{ height: "500px", width: "600px" }}
              />
            </div>
          </div>
          <div className="row mt-5 ">
            <div className="col-lg-7">
              <img
                className="img-fluid rounded "
                src={logo4}
                style={{ height: "500px", width: "600px" }}
              />
            </div>
            <div className="col-lg-5 ">
              <p className="up_para  mt-5 ">
                uPVC windows are a great choice for your home improvement
                project. They are an efficient and cost-effective way to replace
                old windows with new ones. uPVC windows not only look
                attractive, they have superior thermal insulation, soundproofing
                and security features. Their durable construction ensures that
                they last for many years and can reduce your energy bills by
                keeping the heat in during winter and out during summer.
                Furthermore, their easy maintenance makes them more affordable
                than other window types. All of these factors make
              </p>
            </div>
          </div>
          <div className="row mt-5  ">
            <div className="col-lg-12  text-center">
              <button onClick={previous} className="btn bg-primary">
                <Link className="text-white link_btn">Previous Page</Link>
              </button>
            </div>
          </div>
        </div>
      </div> */}
      <div className="conatiner-fluid p-4 bg_colour">
        <div className="container p-3">
          <h3 className="text-center  MyCarousel special_font_head heading_colour">
            Our Updates
          </h3>
          <div className="row mt-3">
            <div className="col-lg-4">
              {" "}
              <div class="zoom-effect">
                <img
                  className="img-fluid up_img rounded"
                  src={logo1}
                  alt="Logo"
                />
              </div>
            </div>
            <div className="col-lg-4">
              {" "}
              <div className="zoom-effect">
                <img className="img-fluid  up_img rounded" src={logo2} />
              </div>
            </div>
            <div className="col-lg-4">
              {" "}
              <div className="zoom-effect">
                <img className="img-fluid  up_img rounded" src={logo3} />
              </div>
            </div>
          </div>
          {/* -----------------2nd------- */}
          <div className="row mt-3">
            <div className="col-lg-12 zoom-effect">
              <img className=" up_img1 rounded " src={logo5} />
            </div>
          </div>
          {/* -----------------------3rd------------ */}
          <div className="row mt-4">
            <div className="col-lg-4">
              {" "}
              <div class="zoom-effect">
                <img
                  className="img-fluid up_img rounded"
                  src={logo4}
                  alt="Logo"
                />
              </div>
            </div>
            <div className="col-lg-4">
              {" "}
              <div className="zoom-effect">
                <img className="img-fluid  up_img rounded" src={logo7} />
              </div>
            </div>
            <div className="col-lg-4">
              {" "}
              <div className="zoom-effect">
                <img className="img-fluid  up_img rounded" src={logo8} />
              </div>
            </div>
          </div>
          {/* --------------------------5th---------------- */}
          <div className="row mt-3">
            <div className="col-lg-12 zoom-effect">
              <img className=" up_img1 rounded " src={logo6} />
            </div>
          </div>
          <div className="row mt-5  ">
            <div className="col-lg-12  text-center">
              <button onClick={previous} className="btn bg-primary">
                <Link className="text-white link_btn">Previous Page</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Update_2;
