import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import "../css/App.css";

import logo from "../img/logo.png";

import "bootstrap/dist/css/bootstrap.min.css";
import Product from "./Product";
import Contact from "./Contact";
import Description from "./Description";
import Description_1 from "./Description_1";
import Description_2 from "./Description_2";
import Upvcwindow from "../compond/Upvcwindow";
import Upvc from "../compond/Upvc";
import DobuleDoor from "../compond/DobuleDoor";
import Partition from "../compond/Partition";
import Casement from "../compond/Casement";
import Titand from "../compond/Titand turn";
import Sliding from "../compond/Sliding";
import Update from "./Update";
import Update_2 from "../compond/Update_2";
import Description_3 from "./Description_3";
import Description_4 from "./Description_4";
import Description_5 from "./Description_5";
import Slidingdoor from "../compond/Slidingdoor";
import Casementdoor from "../compond/casementdoor";
import Tiltandtilldoor from "../compond/Tiltandtilldoor";
import Foldingdoor from "../compond/Foldingdoor";
function App() {
  function closeNavbar() {
    const navbarToggler = document.querySelector(".navbar-toggler");
    if (navbarToggler.getAttribute("aria-expanded") === "true") {
      const navbarCollapse = document.querySelector(".navbar-collapse");
      navbarToggler.classList.add("collapsed");
      navbarToggler.setAttribute("aria-expanded", "false");
      navbarCollapse.classList.remove("show");
    }
  }

  return (
    <Router>
      <nav class="navbar navbar-expand-lg text navbar-dark">
        <div class="container">
          <img
            src={logo}
            alt="Logo"
            class="rounded-pill navbar-brand img-fluid"
            style={{ width: "90px", height: "90px" }}
          />
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarToggler"
            aria-expanded="false"
            aria-label="Toggle navigation"
            s
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            class="collapse navbar-collapse li1 justify-content-center"
            id="navbarNav"
          >
            <ul class="navbar-nav">
              <li class="nav-item ps-5">
                <Link
                  onClick={() => closeNavbar()}
                  className="nav-link text-white link_font"
                  to="/"
                >
                  {" "}
                  Home
                </Link>
              </li>
              <li class="nav-item ps-5">
                <Link
                  onClick={() => closeNavbar()}
                  className="nav-link text-white link_font"
                  to="/about"
                >
                  About
                </Link>
              </li>
              <li class="nav-item ps-5 dropdown">
                <a
                  class="text-white nav-link dropdown-toggle link_font"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                >
                  Product
                </a>
                <ul class="dropdown-menu">
                  <li className="mainmenu">
                    <div>
                      <Link class="dropdown-item">uPVC Window </Link>
                    </div>
                    <ul class="submenu dropdown-menu">
                      <li>
                        <Link
                          // onClick={() => closeNavbar()}
                          class="dropdown-item link_font"
                          to="/upvcpartition"
                        >
                          uPVC Partition
                        </Link>
                      </li>
                      <li>
                        <Link
                          // onClick={() => closeNavbar()}
                          class="dropdown-item link_font"
                          to="/casement"
                        >
                          Casement Window
                        </Link>
                      </li>
                      <li>
                        <Link
                          // onClick={() => closeNavbar()}
                          class="dropdown-item link_font"
                          to="/tiltandtum"
                        >
                          Tilt and turn Window
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link
                          // onClick={() => closeNavbar()}
                          to="/sliding"
                          class="dropdown-item link_font"
                        >
                          Sliding Window
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="mainmenu_1">
                    <div>
                      <Link class="dropdown-item">uPVC Doors </Link>
                    </div>
                    <ul class="submenu_1 dropdown-menu">
                      <li>
                        <Link
                          // onClick={() => closeNavbar()}
                          class="dropdown-item link_font"
                          to="/slidingdoor"
                        >
                          Sliding Doors
                        </Link>
                      </li>
                      <li>
                        <Link
                          // onClick={() => closeNavbar()}
                          class="dropdown-item link_font"
                          to="/casementdoors"
                        >
                          Casement Doors
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          onClick={() => closeNavbar()}
                          class="dropdown-item link_font"
                          to="/tiltandturndoor"
                        >
                          Tilt and turnDoors
                        </Link>
                      </li> */}
                      <li>
                        {" "}
                        <Link
                          // onClick={() => closeNavbar()}
                          to="/foldingdoor"
                          class="dropdown-item link_font"
                        >
                          Folding Doors
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="mainmenu">
                    <Link
                      onClick={() => closeNavbar()}
                      to="/upvc"
                      class="dropdown-item link_font"
                    >
                      FRP Doors
                    </Link>
                  </li>
                </ul>
                {/* <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link
                    onClick={() => closeNavbar()}
                    to="/upvc"
                    class="dropdown-item link_font"
                  >
                    FRP Doors
                  </Link>
                  <a
                    class="dropdown-item link_font  dropdown-toggle "
                    id="doubledoor-link"
                    role="button"
                  >
                    uPVC Window
                    <div class="doubledoor-dropdown">
                      <Link
                        onClick={() => closeNavbar()}
                        class="dropdown-item link_font"
                        to="/upvcpartition"
                      >
                        uPVC Partition
                      </Link>
                      <Link
                        onClick={() => closeNavbar()}
                        class="dropdown-item link_font"
                        to="/casement"
                      >
                        Casement Window
                      </Link>
                      <Link
                        onClick={() => closeNavbar()}
                        class="dropdown-item link_font"
                        to="/tiltandtum"
                      >
                        Tilt and turn Window
                      </Link>
                      <Link
                        onClick={() => closeNavbar()}
                        to="/sliding"
                        class="dropdown-item link_font"
                      >
                        Sliding Window
                      </Link>
                    </div>
                  </a>
                  <a
                    class="dropdown-item link_font  dropdown-toggle "
                    id="doubledoor-link"
                    role="button"
                  >
                    uPVC Doors
                    <div class="doubledoor-dropdown">
                      <Link
                        onClick={() => closeNavbar()}
                        class="dropdown-item link_font"
                        to="/slidingdoor"
                      >
                        Sliding Doors
                      </Link>
                      <Link
                        onClick={() => closeNavbar()}
                        class="dropdown-item link_font"
                        to="/casementdoors"
                      >
                        Casement Doors
                      </Link>
                      <Link
                        onClick={() => closeNavbar()}
                        class="dropdown-item link_font"
                        to="/tiltandturndoor"
                      >
                        Tilt and turnDoors
                      </Link>
                      <Link
                        onClick={() => closeNavbar()}
                        to="/foldingdoor"
                        class="dropdown-item link_font"
                      >
                        Folding Doors
                      </Link>
                    </div>
                  </a>
                </div> */}
              </li>
              <li class="nav-item ps-5">
                <Link
                  onClick={() => closeNavbar()}
                  className="nav-link text-white link_font"
                  to="/update"
                >
                  Updates
                </Link>
              </li>

              <li class="nav-item ps-5  justify-content-end">
                <Link
                  onClick={() => closeNavbar()}
                  className="nav-link text-white link_font "
                  to="/contactUs"
                >
                  Contact us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/product" element={<Product />}></Route>
        <Route path="/contactUs" element={<Contact />} />
        <Route path="/description" element={<Description />} />
        <Route path="/description_1" element={<Description_1 />} />
        <Route path="/description_2" element={<Description_2 />} />
        <Route path="/description_3" element={<Description_3 />} />
        <Route path="/halfparition" element={<Description_4 />} />
        <Route path="/casement" element={<Description_5 />} />
        <Route path="/upvcwindow" element={<Upvcwindow />} />
        <Route path="/upvc" element={<Upvc />} />
        <Route path="/dobuledoor" element={<DobuleDoor />} />
        <Route path="/upvcpartition" element={<Partition />} />
        <Route path="/casement" element={<Casement />} />
        <Route path="/tiltandtum" element={<Titand />} />
        <Route path="sliding" element={<Sliding />} />
        <Route path="/update" element={<Update />} />
        <Route path="/update_2" element={<Update_2 />} />
        <Route path="/slidingdoor" element={<Slidingdoor />}></Route>
        <Route path="/casementdoors" element={<Casementdoor />} />
        <Route path="/tiltandturndoor" element={<Tiltandtilldoor />} />
        <Route path="/foldingdoor" element={<Foldingdoor />} />
      </Routes>
    </Router>
  );
}

export default App;
