import React from "react";
import logo from "../img/logot.png";
import "../css/About.css";
import logo10 from "../image/img-7.avif";
import logo11 from "../image/img-8.avif";
import Footer from "../compond/Footer";

const About = () => {
  return (
    <>
      <div className="container-fluid   p-4  ">
        <div className="container containers ">
          <div className="row  p-2    colour ">
            <div className="col-lg-5 text-center  ">
              {" "}
              <img className=" colour img-fluid " src={logo} />
            </div>
            <div className="col-lg-6  back_font">
              {" "}
              <h2 className="our_font">Our Story</h2>
              <p className="special_font " style={{ fontSize: "19px" }}>
                {" "}
                Ever Green Windows &amp; Doors presents a comprehensive range of
                high- performance uPVC door and window solutions. It offers the
                perfect mix of style &amp; safety, the right balance between
                elegance &amp; efficiency and the ultimate combination of form
                &amp; function. As a part of Ever Green Windows &amp; Doors
                offers expert knowledge and a wide portfolio on glass – which is
                the biggest in the country. It also provi...
              </p>
            </div>
          </div>
        </div>
        <div className="row text-center mt-4 ">
          <h2 className=" heading_colour special_font_head mt-3 ">
            Other updates{" "}
          </h2>
        </div>
        <div className="row justify-content-around mt-5 bg_colour p-3">
          <div className="col-lg-6   ">
            {" "}
            <img className="img-fluid img-thumbnail img-colour" src={logo10} />
            <div className=" mt-4 special_font back_font">
              <ul className="para">
                <li> uPVC windows manufacture in Tirupur</li>
                <li> "uPVC windows manufacture in Avinashi"</li>
                <li> uPVC windows manufacture in Tirupur</li>
                <li> uPVC windows manufacture in Tirupur</li>
                <li> Guts uPVC windows Supplires in Thanjavur</li>
                <li> uPVC windows manufacture in Tiruchy</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <img className="img-fluid img-thumbnail img-colour" src={logo11} />
            <div className=" mt-4 back_font">
              <ul className="para special_font">
                <li>uPVC windows manufacture in Tirupur</li>
                <li>uPVC windows manufacture in Avinashi</li>
                <li>uPVC windows manufacture in avinashi road</li>
                <li>uPVC windows manufacture in palladam</li>
                <li>uPVC windows manufacture in tirupur road</li>
                <li>uPVC windows manufactures in Tirupur</li>
              </ul>
            </div>
          </div>
        </div>
        {/* ------------------whatsapp----------- */}
        <div className="whatsapp-icon">
          <a className="footer-link" href="https://wa.me/919489729590">
            <i className="fa-brands fa-whatsapp icons"></i>
          </a>
        </div>
        {/* --------------------banner----------------- */}
        <div className="container mt-4">
          <div className="row justify-content-center">
            <h2 className="special_font_head text-center  heading_colour">
              Why choose we...?
            </h2>
            <div className="col-lg-9 mt-2 shadow text-center p-3">
              <h5 className="para_2">Quality Products</h5>

              <p className="specialfont_para">
                {" "}
                Customers often prioritize high-quality windows and doors that
                are durable, energy-efficient, and aesthetically pleasing. If a
                company offers superior products that meet these criteria,
                customers are more likely to choose them.
              </p>
              <h5 className="para_2">Variety of Options:</h5>
              <p className="specialfont_para">
                A company that offers a wide range of window and door styles,
                materials, colors, and customization options can cater to
                diverse customer preferences and architectural styles.
              </p>
              <h5 className="para_2">Expertise and Experience: </h5>
              <p className="specialfont_para">
                Customers may prefer a company with a proven track record of
                expertise and experience in the window and door industry. This
                can include skilled craftsmanship, knowledge of the latest
                trends and technologies, and a history of successful
                installations.
              </p>
              <h5 className="para_2"> Customer Service:</h5>
              <p className="specialfont_para">
                Exceptional customer service, including responsiveness,
                professionalism, and transparency, can significantly influence a
                customer's decision. A company that prioritizes customer
                satisfaction and provides support throughout the buying process
                and beyond is often preferred.
              </p>
              <h5 className="para_2">Competitive Pricing:</h5>
              <p className="specialfont_para">
                While quality is essential, competitive pricing is also a
                significant factor for many customers. A company that offers
                fair and transparent pricing without sacrificing quality can be
                appealing to budget-conscious customers.
              </p>
              <h5 className="para_2">Sustainability and Energy Efficiency: </h5>
              <p className="specialfont_para">
                Increasingly, customers are seeking environmentally friendly and
                energy-efficient solutions for their homes. A company that
                prioritizes sustainability and offers energy-efficient windows
                and doors may have a competitive edge.
              </p>
              <h5 className="para_2">Ease of Installation and Maintenance:</h5>
              <p className="specialfont_para">
                {" "}
                Finally, customers value convenience when it comes to
                installation and maintenance. A company that offers efficient
                installation services and provides guidance on proper
                maintenance can make the process smoother for customers.
              </p>
            </div>
          </div>
        </div>
        <div className="container icons2 mt-3 p-4">
          <div className="row">
            <h3 className=" text-center special_font_head">
              Elevate your home with our trusted window and
              <br /> door solutions.
            </h3>
            <marquee behavior="scroll" direction="left">
              <div className="col-lg-12 d-flex flex-column flex-lg-row  justify-content-around mt-5">
                <div className="icons2 p-5 text-center bg-dark text-white rounded mt-3  gap-2">
                  <i class="fa-solid fa-circle-check icons1"></i>
                  <h4 className="font mt-3">Proven Expertise</h4>
                </div>
                <div className=" icons2  p-5 text-center bg-dark text-white rounded mt-3 g-2">
                  {" "}
                  <i class="fa-solid fa-tags icons1"></i>
                  <h4 className="font  mt-3">Our Pricing</h4>
                </div>
                <div className="  icons2  p-5 text-center bg-dark text-white rounded mt-3 ">
                  <i class="fa-solid fa-medal icons1"></i>

                  <h4 className="font  mt-3">High-Quality</h4>
                </div>
                <div className="icons2 p-5 text-center bg-dark text-white rounded mt-3 ">
                  {" "}
                  <i class="fa-regular fa-handshake icons1"></i>
                  <h4 className="font  mt-3"> Customer Service</h4>
                </div>
              </div>
            </marquee>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
