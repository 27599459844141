import React from "react";
import logo8 from "../img/half.jpeg";
const Description_4 = () => {
  return (
    <>
      {" "}
      <div className="container-fluid bg_3">
        <div className="container ">
          <div className="row   ">
            <h3 className=" text-center mt-4">uPVC Partition</h3>

            <div className="col-lg-5 animate__animated animate__bounceInLeft mt-1">
              <h5 className=" text-center mt-5 heads_font">DESCRIPTION</h5>
              <p className="mt-3  para_1 ">
                Half partitioning is a design technique commonly used in
                interior architecture and space planning. It involves dividing a
                room or space horizontally, typically up to waist or chest
                height, to create separate zones while maintaining a sense of
                openness and visual connection. Half partitioning helps define
                different functional areas within a room without completely
                enclosing them, allowing for a more open and spacious feel. By
                dividing the space horizontally, half partitions provide visual
                separation between different zones while still allowing light
                and airflow to pass through.: While not completely blocking off
                areas, half partitions can provide a degree of privacy, making
                them suitable for dividing areas such as workspaces or dining
                areas within larger rooms.
              </p>
            </div>
            <div className="col-lg-7">
              <img
                className="img-fluid img_des  mt-5"
                src={logo8}
                alt="Description"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Description_4;
